import {datadogLogs} from '@datadog/browser-logs';
import {datadogRum} from '@datadog/browser-rum';
import Auth from 'Auth';
import {Button, Collapse, Space, message} from 'antd';
import React from 'react';

const handleRTKQError = (error, meta) => {
  // console.log('error', JSON.stringify(error, null, 2));

  const request = {
    url: meta?.request?.url,
    method: meta?.request?.method,
    data: meta?.request?.data,
  };

  const response = {
    status: meta?.response?.status,
    statusText: meta?.response?.statusText,
    data: meta?.response?.data,
  };

  // console.log('request', JSON.stringify(request, null, '\n'));
  // console.log('response', JSON.stringify(response, null, '\n'));

  error.data =
    !error.data || typeof error.data === 'string'
      ? {
          detail: error.data,
        }
      : error.data;

  const parsedError = {
    ...error,
    data: {
      detail:
        error.data?.detail ?? error.data?.error ?? error.message ?? error.error,
    },
    response,
    request,
    status: error.originalStatus ?? response.status ?? error.status,
    url: request?.url,
    method: request?.method,
  };

  // if data detail is html, extract text
  if (parsedError.data.detail.indexOf('<!DOCTYPE html>') !== -1) {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(
      parsedError.data.detail,
      'text/html'
    );
    parsedError.data.detail = htmlDoc.body.textContent;

    // parse so that the error message is more readable, remove extra spaces and newlines
    parsedError.data.detail = parsedError.data.detail
      .replace(/\s+/g, ' ')
      .replace(/\n/g, ' ');
  }

  handleApiError(parsedError);
};

const handleApiError = (error) => {
  // if datadog_session_id is undefined in localStorage, set it
  if (!localStorage.getItem('datadog_session_id')) {
    // console.log('setting datadog session id');
    localStorage.setItem(
      'datadog_session_id',
      datadogRum.getInternalContext()?.session_id
    );
  }

  const location = window.location.href;
  const user = Auth.getUser();

  // if localhost, don't send to datadog
  if (window.location.hostname !== 'localhost') {
    datadogLogs.logger.error(
      error.data.detail,
      {
        usr: user,
        location,
      },
      error
    );
  }

  const reportErrorToIT = () => {
    const message = `Hi!\n\nAn error occurred on TRACK. \n\nUser details: ${JSON.stringify(user, null, 2)}\n\nError details:${JSON.stringify(error, null, 2)}\n\nThank you!`;
    const mailtoLink = `mailto:itsupport@uncommonschools.org?subject=TRACK Error&body=${encodeURIComponent(message)}`;
    window.location.href = mailtoLink;
  };

  // if not a 401 or 403 error, log to console as error
  if (error.status !== 401 && error.status !== 403) {
    console.error('handleApiError', JSON.stringify(error, null, 2));
  } else {
    console.warn('handleApiError', JSON.stringify(error, null, 2));
  }

  const loginButton = (
    <Button
      onClick={() => {
        localStorage.setItem(
          'redirect',
          window.location.pathname.includes('login')
            ? '/'
            : window.location.pathname
        );
        Auth.logout();
        message.destroy();
        window.location.href = '/login';
      }}
      type="primary"
    >
      Log In
    </Button>
  );
  const homeButton = (
    <Button
      onClick={() => {
        localStorage.setItem('redirect', window.location.pathname);
        Auth.logout();
        message.destroy();
        window.location.href = '/';
      }}
    >
      Home
    </Button>
  );
  const reportButton = (
    <Button onClick={reportErrorToIT}>Report to IT Support</Button>
  );
  const closeButton = <Button onClick={() => message.destroy()}>Close</Button>;
  const backButton = (
    <Button onClick={() => window.history.back()}>Back</Button>
  );

  const unauthenticatedContent = (
    <div>
      <p>
        Your session has expired.
        <br />
        You will need to login again to access TRACK.
      </p>
      <Space>
        {loginButton}
        {homeButton}
        {reportButton}
      </Space>
    </div>
  );

  const unauthorizedContent = (err) => (
    <div>
      {err.data?.error ? (
        <p style={{wrap: 'break-word'}}>{err.data?.detail}</p>
      ) : null}
      <p>
        You do not have permission to access TRACK.
        <br />
        Please contact IT to revise permissions.
      </p>
      <Space>
        {loginButton}
        {homeButton}
        {reportButton}
      </Space>
    </div>
  );

  const permissionDeniedContent = (
    <div>
      <p>
        You don't have permission to view this page.
        <br />
        If you believe this is an error, please try to log in again.
        <br />
        If the issue persists, please contact IT Support.
      </p>
      <Space>
        {backButton}
        {loginButton}
        {reportButton}
      </Space>
    </div>
  );

  const missingResourceContent = (err) => (
    <div>
      <p style={{wrap: 'break-word'}}>
        {err.data?.detail ?? 'Resource not found'}
      </p>
      <Space>
        {backButton}
        {closeButton}
      </Space>
    </div>
  );

  const serverErrorContent = (
    <div>
      <p>
        An error occurred on the server.
        <br />
        Please try again or contact support if the issue persists.
      </p>
      <Space>
        {reportButton}
        {closeButton}
      </Space>
    </div>
  );

  const unexpectedErrorContent = (
    <div>
      <p>
        An unexpected error occurred.
        <br />
        Please try again or contact support if the issue persists.
      </p>
      <Space>
        {reportButton}
        {closeButton}
      </Space>
    </div>
  );

  const misconfiguredContent = (
    <div>
      <p>
        The requested resource has a misconfiguration.
        <br />
        Please contact IT Support.
      </p>
      <Space>
        {reportButton}
        {closeButton}
      </Space>
    </div>
  );

  const errorTypes = (err) => {
    switch (err.status) {
      case 400:
        return missingResourceContent(err);
      case 401:
        return [
          'Invalid Token',
          'Authentication credentials were not provided.',
          'Unauthorized',
        ].indexOf(err?.data?.detail) !== -1
          ? unauthenticatedContent
          : unauthorizedContent(err);
      case 403:
        return permissionDeniedContent;
      case 404:
        return missingResourceContent(err);
      case 500:
        if (err.data?.detail === 'Internal Server Error') {
          return serverErrorContent;
        } else if (err.data?.detail === 'Not Found') {
          return missingResourceContent(err);
        } else if (err.data?.detail === 'Permission Denied') {
          return permissionDeniedContent;
        } else if (err.data?.detail === 'Unauthorized') {
          return unauthorizedContent(err);
        } else if (err.data?.detail === 'Invalid Token') {
          return unauthenticatedContent;
        } else if (
          err.config?.data &&
          err.config?.data?.indexOf('{"code":null}') !== -1
        ) {
          return unauthorizedContent(err);
        } else {
          return serverErrorContent;
        }
      case 199:
        return misconfiguredContent;
      default:
        return unexpectedErrorContent;
    }
  };

  message.warning({
    content: (
      <>
        {error ? errorTypes(error) : errorTypes({status: 500})}
        <Collapse ghost>
          <Collapse.Panel
            header="Details"
            key="1"
            style={{
              textAlign: 'start',
              fontSize: '12px',
              overflow: 'auto',
              maxHeight: '80vh',
              maxWidth: '50vw',
            }}
          >
            {/* list any important attributes */}
            <div>
              <strong>URL: </strong> {error.url}
            </div>
            <div>
              <strong>Method: </strong> {error.method}
            </div>
            <div>
              <strong>Location: </strong> {location}
            </div>
            <div>
              <strong>Status: </strong> {error.status}
            </div>
            <div>
              <strong>User: </strong> {user?.email}
            </div>
            <div>
              <strong>Sub: </strong> {user?.sub}
            </div>
            <div>
              <strong>Datadog Session ID: </strong> {user?.datadog_session_id}
            </div>
            <div>
              <strong>Token: </strong> {user?.token}
            </div>
            <div>
              <strong>PS Site Code: </strong> {user?.ps_site_code}
            </div>
            <div>
              <strong>Permissions: </strong>{' '}
              <pre style={{display: 'inline'}}>
                {JSON.stringify(user?.permissions, null, 2)}
              </pre>
            </div>
            <div>
              <strong>Detail: </strong>
              <pre style={{display: 'inline'}}>
                {JSON.stringify(error.data?.detail, null, 2)}
              </pre>
            </div>
            <div>
              <strong>Request: </strong>
              <pre style={{display: 'inline'}}>
                {JSON.stringify(error.request, null, 2)}
              </pre>
            </div>
            <div>
              <strong>Response: </strong>
              <pre style={{display: 'inline'}}>
                {JSON.stringify(error.response, null, 2)}
              </pre>
            </div>
          </Collapse.Panel>
        </Collapse>
      </>
    ),
    duration: 0,
    key: 'apiError',
  });
};

export {handleRTKQError, handleApiError};
